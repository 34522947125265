/* new css */
:focus-visible {
  outline: 0;
}

.new-header {
  background: #ed7d31;
  padding: 16px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 14px;
}

.new-header h1 {
  font-family: "Roboto";
  color: #442020;
  font-size: 24px;
  line-height: normal;
  font-weight: 400;
  letter-spacing: 0.3px;
}

ul.custom-nav-list {
  display: flex;
  list-style: none;
  column-gap: 30px;
  height: auto;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  column-gap: 60px;
}

ul.custom-nav-list li {
  height: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  cursor: pointer;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  font-family: "Roboto";
}

ul.custom-nav-list li.active {
  color: #fb5b01;
  text-decoration: underline;
}

ul.custom-nav-list li:hover {
  color: #fb5b01;
}

.search-form {
  display: flex;
  align-items: center;
  max-width: 540px;
  min-width: 540px;
  position: relative;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.input-group .form-control {
  background: #fff;
  border: 0;
  padding: 10px 46px 10px 172px;
  width: 100%;
  font-size: 18px;
  font-weight: 400;
  font-family: "Roboto";
  font-style: italic;
  border-radius: 10px;
}

.input-group-append {
  position: absolute;
  top: 7px;
  left: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.search-icon {
  width: auto;
  height: 21px;
  object-fit: contain;
  padding-top: 3px;
}

.input-group-append .btn {
  border: 0;
  background: transparent;
  cursor: pointer;
}

.input-group-append span {
  font-style: italic;
  font-weight: 700;
  font-size: 18px;
  line-height: normal;
  letter-spacing: 0.48px;
  font-family: "Roboto";
}

.submit-btn {
  position: absolute;
  right: 0;
  background: #000;
  color: #fff;
  height: 100%;
  font-size: 18px;
  line-height: normal;
  font-weight: 400;
  font-family: "Roboto";
  font-style: italic;
  border: 0;
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-group {
  height: 100%;
}

.title-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 60%;
}

.title-header h2 {
  font-family: "Roboto";
  font-size: 24px;
  font-weight: 400;
  color: #000;
  letter-spacing: 0.48px;
}

.title-header > h2:last-child {
  text-align: end;
  font-size: 28px;
  font-weight: 500;
  /* font-family: "MulishBold"; */
}

.get-inspired-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin-top: 60px;
}

.add-items-text h2 {
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  color: #000;
  letter-spacing: 0.48px;
  font-family: "Roboto";
  text-align: center;
}

.curate-look-text {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  font-size: 30px !important;
  line-height: normal !important;
  /* font-weight: 700 !important; */
  color: #000 !important;
  font-family: "New York" !important;
}

.curate-look-text {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 40px;
  line-height: normal;
  font-weight: 700;
  color: #000;
  font-family: auto;
}

.get-inspired-title h2 {
  font-size: 32px;
  font-weight: normal;
  font-family: "New York";
  line-height: normal;
  color: #000;
}

.get-inspired-title p {
  font-size: 30px;
  line-height: normal;
  color: #000;
  font-weight: 500;
  margin-bottom: 40px;
  font-family: "Mulish";
  text-align: center;
}

.activeSearch {
  color: #fb5b01;
}

.suggestions-dropdown-box {
  background: #fff;
  width: -webkit-fill-available;
  position: absolute;
  top: 43px;
  z-index: 1;
  box-shadow: 0 0 4px rgb(0 0 0 / 25%);
  max-width: 100%;
  border-radius: 10px;
}

ul.suggestions-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: auto;
  width: 100%;
}

li.suggestion-item {
  font-size: 18px;
  font-weight: 400;
  color: #282828;
  font-family: "Roboto";
  font-style: italic;
  text-shadow: 0px 2px #00000040;
  padding: 8px 4px 8px 176px;
  width: -webkit-fill-available;
  cursor: pointer;
}

li.suggestion-item:hover {
  background: #d9d9d973;
}

li.suggestion-item.active-suggestion {
  background: #d9d9d973;
}

li.suggestion-item:first-child:hover {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

li.suggestion-item:last-child:hover {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

/* .curations-slide {
    width: 33.33%;
  } */
.skeleton-loader-wrapper {
  display: flex;
  align-items: center;
  gap: 40px;
  justify-content: center;
  margin-bottom: 20px;
}

.skeleton-loader-wrapper.curation-slider-loader .get-inspired-skeleton-child {
  height: 500px;
  max-height: 500px;
}

.get-inspired-skeleton-child {
  height: 400px;
  max-width: 400px;
}

.get-inspired-skeleton {
  width: 100%;
  max-width: 400px;
}

.refresh-pagination-row {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 20px;
  margin-top: 10px;
  flex-wrap: wrap;
}

.result-grid-box {
  width: 442px;
  border: 1px solid #151515;
  height: 593px;
}

.search-result-grid-boxes-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  column-gap: 50px;
  row-gap: 10px;
  margin-top: 30px;
}

.custom-main-page-wrapper .slick-slide {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.data-not-found-wrapper-model {
  width: 480px !important;
  min-height: 348px !important;
  padding: 20px 10px !important;
  align-items: center;
  justify-content: center;
}

.data-not-found-wrapper-model > div {
  height: auto;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.data-not-found-wrapper-model > div:nth-child(odd) {
  margin-bottom: 30px;
}
.no-item-text {
  margin-top: 20px;
  font-size: 24px;
  line-height: normal;
  color: #000;
}
.bottom-fixed-text {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 10px 0 20px;
}

.bottom-fixed-text p {
  font-size: 20px;
  line-height: normal;
  font-family: "Roboto";
  font-weight: 400;
  color: #cacaca;
}
/* Responsive css */
@media (max-width: 1280px) {
  .title-header h2 {
    font-size: 22px;
  }

  .title-header > h2:last-child {
    font-size: 24px;
  }

  .curate-look-text,
  .get-inspired-title h2 {
    font-size: 28px !important;
  }

  .get-inspired-title p {
    font-size: 26px;
    margin-bottom: 30px;
  }
}

@media (max-width: 1199px) {
  .search-result-grid-boxes-wrapper {
    column-gap: 16px;
  }

  .get-inspired-skeleton-child {
    height: 350px;
    max-width: 400px;
  }

  ul.custom-nav-list li {
    font-size: 18px;
  }

  .new-header h1 {
    font-size: 22px;
  }

  .title-header h2 {
    font-size: 20px;
  }

  .title-header > h2:last-child {
    font-size: 22px;
  }
}

@media (max-width: 1024px) {
  .result-grid-box {
    width: 296px;
    height: 400px;
  }

  ul.custom-nav-list {
    column-gap: 30px;
  }

  ul.custom-nav-list li {
    font-size: 16px;
  }
}

@media (max-width: 991px) {
  .get-inspired-title p {
    font-size: 22px;
    margin-bottom: 20px;
  }

  .curate-look-text,
  .get-inspired-title h2 {
    font-size: 24px !important;
  }

  .refresh-pagination-row {
    margin-top: 4px;
  }

  .curation-slider-loader .get-inspired-skeleton:last-child {
    display: none;
  }

  ul.custom-nav-list {
    justify-content: center;
    gap: 40px;
  }

  .new-header h1 {
    text-align: center;
  }

  li.suggestion-item {
    font-size: 16px;
  }

  ul.custom-nav-list li {
    font-size: 16px;
  }

  .title-header h2 {
    font-size: 18px;
  }

  .title-header > h2:last-child {
    font-size: 20px;
  }
}

@media (max-width: 940px) {
  .slick-slide > div {
    margin: 0px auto;
  }
}

@media (max-width: 900px) {
  ul.custom-nav-list li {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.2px;
    text-align: center;
  }
}

@media (max-width: 800px) {
  .title-header h2,
  .title-header > h2:last-child {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .new-header h1 {
    font-size: 20px;
  }

  .input-group .form-control,
  .submit-btn {
    font-size: 16px;
  }

  .input-group-append span {
    font-size: 14px;
  }

  .input-group .form-control {
    padding: 10px 46px 10px 144px;
  }

  .get-inspired-skeleton-child {
    height: 250px;
    max-width: 400px;
  }

  .skeleton-loader-wrapper {
    gap: 16px;
  }

  ul.custom-nav-list {
    justify-content: center;
    gap: 20px;
  }

  ul.custom-nav-list li {
    font-size: 12px;
  }

  .search-form {
    max-width: 100%;
    min-width: unset;
    width: 100%;
  }

  .title-header {
    gap: 40px;
    width: 70%;
    justify-content: flex-start;
  }

  .get-inspired-title {
    margin-top: 30px;
  }
}

@media (max-width: 600px) {
  .title-header {
    gap: 10px;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .new-header h1 {
    font-size: 18px;
    text-align: center;
  }

  ul.custom-nav-list {
    justify-content: center;
    gap: 18px;
    flex-wrap: wrap;
  }
  ul.custom-nav-list li {
    padding: 0 4px;
  }

  .input-group-append span {
    font-size: 14px;
  }

  .input-group .form-control {
    padding: 10px 46px 10px 140px;
  }

  .input-group-append {
    left: 8px;
    gap: 6px;
  }

  .submit-btn {
    font-size: 14px;
    padding: 12px;
  }

  .new-header {
    padding: 20px;
  }

  .suggestions-dropdown-box {
    top: 41px;
  }

  li.suggestion-item {
    font-size: 14px;
    padding: 8px 4px 8px 140px;
  }
}

@media (max-width: 575px) {
  .get-inspired-skeleton:not(:first-child),
  .curation-slider-loader .get-inspired-skeleton:last-child {
    display: none !important;
  }

  .new-header h1 {
    font-size: 16px;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .title-header h2,
  .title-header > h2:last-child {
    font-size: 14px;
  }

  .curate-look-text,
  .get-inspired-title h2 {
    font-size: 22px !important;
  }

  .get-inspired-title p {
    font-size: 20px;
    margin-bottom: 16px;
  }

  .new-header h1 {
    font-size: 14px;
    text-align: center;
  }

  .new-header {
    padding: 12px 15px;
  }

  ul.custom-nav-list {
    justify-content: center;
    gap: 10px 16px;
    flex-wrap: wrap;
    padding: 14px 0;
  }
}

@media (max-width: 450px) {
  .result-grid-box {
    width: 296px;
    height: 400px;
  }
  .bottom-fixed-text {
    margin: 20px 0 0px;
  }
}
