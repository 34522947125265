.MuiPopover-paper {
  min-width: 200px !important;
  max-width: 350px !important;
}

.MuiMenuItem-root {
  padding-left: 12px !important;
}

.MuiTypography-root {
  padding-left: 0 !important;
}

.multi-select-options {
  display: flex;
  flex-direction: column;
  /* border: 1px solid #ccc; */
  /* padding: 10px; */
  /* border-radius: 4px; */
  font-family: var(--primaryRegularFont);
  position: relative;
}

.multi-select-option {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.multi-select-option input[type="checkbox"] {
  margin-right: 2px;
  width: 17.5px;
  /* Size of the checkbox */
  height: 18.5px;
}

.multi-select-option-group {
  /* border: 1px solid #797979; */
  margin-bottom: 10px;
  padding: 5px 18px;
  box-shadow: 0 0 3px #4b4b4b;
  border-radius: 10px;
}

.placeholder {
  color: #999;
  position: absolute;
  top: 10px;
  left: 10px;
  pointer-events: none;
}

.selected-options {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  height: auto;
}

.selected-option {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 0px 5px;
}

.custom-checkbox {
  border: 2px solid #CACACA;
  appearance: none;
  -webkit-appearance: none;
  border-radius: 5px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  background-color: white;
  position: relative;
  display: inline-block;
  background-size: 24px 24px;
  background-position: center;
}

.custom-checkbox:checked {
  background-image: url('data:image/svg+xml,%3Csvg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M9.79203 0.332031H4.20536C1.7787 0.332031 0.332031 1.7787 0.332031 4.20536V9.78536C0.332031 12.2187 1.7787 13.6654 4.20536 13.6654H9.78536C12.212 13.6654 13.6587 12.2187 13.6587 9.79203V4.20536C13.6654 1.7787 12.2187 0.332031 9.79203 0.332031ZM10.1854 5.46536L6.40536 9.24536C6.35903 9.29189 6.30397 9.3288 6.24333 9.35399C6.1827 9.37918 6.11769 9.39214 6.05203 9.39214C5.98637 9.39214 5.92136 9.37918 5.86073 9.35399C5.80009 9.3288 5.74503 9.29189 5.6987 9.24536L3.81203 7.3587C3.76291 7.31292 3.72351 7.25772 3.69618 7.19639C3.66885 7.13506 3.65415 7.06885 3.65297 7.00171C3.65179 6.93458 3.66414 6.86789 3.68928 6.80563C3.71443 6.74337 3.75186 6.68682 3.79934 6.63934C3.84682 6.59186 3.90337 6.55443 3.96563 6.52928C4.02789 6.50414 4.09458 6.49179 4.16171 6.49297C4.22885 6.49415 4.29506 6.50885 4.35639 6.53618C4.41772 6.5635 4.47292 6.60291 4.5187 6.65203L6.05203 8.18536L9.4787 4.7587C9.57348 4.67038 9.69884 4.6223 9.82838 4.62458C9.95791 4.62687 10.0815 4.67934 10.1731 4.77095C10.2647 4.86256 10.3172 4.98615 10.3195 5.11568C10.3218 5.24522 10.2737 5.37058 10.1854 5.46536Z" fill="%23fb5b0180"/%3E%3C/svg%3E');
  border: none;
}

.custom-checkbox:checked::after {
  content: "";
  display: none;
}

.custom-checkbox:focus {
  outline: none;
}

.multi-select-option-group {
  /* display: flex;
  flex-direction: column; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(3, auto);
  gap: 10px;
}

.multi-select-option {
  display: flex;
  align-items: center;
  /* Align checkbox and label */
  margin-bottom: 8px;
  /* Space between options */
}

label {
  margin-left: 8px;
  /* Space between checkbox and label */
}

/* .dropdown-options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
} */